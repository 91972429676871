/* General Styles */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: white;
  border: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.2em;
  min-height: -webkit-fill-available;
}


/* Desktop styles */
df-messenger {
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  --df-messenger-font-color: #333;
  --df-messenger-font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  --df-messenger-font-size: 18px;
  --df-messenger-chat-background: #fff; 
  --df-messenger-message-user-background: #f1f3f4;
  --df-messenger-message-bot-background: #fff;
  --df-messenger-input-background: #fff;
  --df-messenger-primary-color: #0056b3;
  --df-messenger-focus-color: #0056b3;
  --df-messenger-send-icon-color: #0056b3;
  --df-messenger-send-icon-color-hover: #0056b3;
  --df-messenger-send-icon-color-active: #0056b3;
  width: 40%;
  height: calc(100% - 50px);
  border: none;
}

/* Tablet styles */
@media (max-width: 1024px) {
  df-messenger {
    width: 80%;
    height: calc(100% - 50px);
  }
  .sidebar {
    display: none;
  }

  .fixed-top-right {
    display: block;
  }
  .flex-grow.relative.z-0 {
    padding-top: 2rem; /* Adjust this value as needed */
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  df-messenger {
    width: 100%;
    padding-bottom: 55px
  }


  .sidebar {
    display: none;
  }

  .new-session-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #0056b3;
    color: white;
    border-radius: 50%;
    cursor: pointer;
  }

  .button-suggestions {
    margin-top: 10px;
  }

  .suggestions-container .hidden-mobile {
    display: none;
  }

  .button-suggestions .title {
    font-size: 1.25rem; /* Decrease font size for title */
  }

  .button-suggestions .option {
    font-size: 0.875rem; /* Decrease font size for options */
  }

  .button-suggestions-container {
    top: 20%; /* Adjust this value to pull the suggestions higher */
  }

  .flex-grow.relative.z-0 {
    padding-top: 1rem; /* Adjust this value as needed */
  }
}

/* Custom styles for df-messenger */
df-messenger df-messenger-chat {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important; 
  margin: 0 !important;
  border: none;
}

df-messenger .df-messenger-wrapper {
  border: none !important;
}

df-messenger .df-messenger-chat-wrapper {
  top: 0 !important; 
}

df-messenger .df-messenger-input {
  background: #fff !important; 
  border: none;
  text-align: left;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  padding: 10px;
}

/* Messages */
df-messenger .df-messenger-message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 12px;
}

df-messenger .df-messenger-message-user {
  background-color: #f1f3f4;
  text-align: right;
  border-radius: 20px 20px 0 20px;
  margin-left: auto;
  margin-right: 10px;
  max-width: 70%;
}

df-messenger .df-messenger-message-bot {
  background-color: #fff;
  text-align: left;
  border-radius: 0;
  margin-right: auto;
  max-width: 70%;
  padding: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Generator Content */
df-messenger .df-messenger-generator-container,
df-messenger .df-messenger-quick-replies,
df-messenger .df-messenger-card,
df-messenger .df-messenger-rich-content {
  text-align: left !important;
  align-self: flex-start !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Quick Replies */
df-messenger .df-messenger-quick-reply {
  background-color: #f1f3f4;
  border-radius: 16px;
  margin: 4px 0;
  padding: 8px 16px;
  color: #333;
  text-align: left;
  cursor: pointer;
}

df-messenger .df-messenger-quick-reply:hover {
  background-color: #e0e0e0;
}

/* Cards */
df-messenger .df-messenger-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 10px 0;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  align-self: flex-start;
}

df-messenger .df-messenger-card-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}


df-messenger .df-messenger-card-content {
  font-size: 14px;
  margin-bottom: 8px;
}

df-messenger .df-messenger-card-buttons {
  display: flex;
  flex-direction: column;
}

df-messenger .df-messenger-card-button {
  background-color: #0056b3;
  color: #fff;
  border: none;
  border-radius: 16px;
  padding: 8px 16px;
  margin: 4px 0;
  text-align: center;
  cursor: pointer;
}

df-messenger .df-messenger-card-button:hover {
  background-color: #004494;
}

/* Custom Animations */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 1s ease-out forwards;
}

.staggered-fade-in {
  opacity: 0;
  animation: fadeInUp 0.5s ease-out forwards;
  animation-delay: var(--animation-delay, 0s);
}

.hover-scale {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.hover-scale:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.custom-scrollbar-container {
  height: 100%;
  width: 100%;
}

.scroll-hidden {
  overflow: hidden;
}
